import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as styles from './Heading.module.scss';

const Heading = ({ title, text, theme, className, alignment }) => {
  const classes = classNames(className, styles.intro, {
    [styles.light]: theme === 'light',
    [styles.alignLeft]: alignment === 'left',
  });

  return (
    <div className={classes}>
      <h2>{title}</h2>
      {text && <p>{text}</p>}
    </div>
  );
};

Heading.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  theme: PropTypes.string,
  className: PropTypes.string,
  alignment: PropTypes.string,
};

Heading.defaultProps = {
  alignment: 'center',
};

export default Heading;
