/* ----------
	Maps block type to correct component
---------- */
import React from 'react';

/* Components */
import Accordion from './Accordion/Accordion';
import Banner from './Banner/Banner';
import SMS from './Sms/Sms';
import SellingPoints from './SellingPoints/SellingPoints';
import VideoBanner from './VideoBanner/VideoBanner';
import TextArticle from './TextArticle/TextArticle';
import TextSection from './TextSection/TextSection';
import TextBlocks from './TextBlocks/TextBlocks';
import PebbleSection from './PebbleSection/PebbleSection';
import ScrollCards from './ScrollCards/ScrollCards';
import PebbleFontPreview from './PebbleFontPreview/PebbleFontPreview';
import ScrollImageGallery from './ScrollImageGallery/ScrollImageGallery';
import ExamplesSlider from './ExamplesSlider/ExamplesSlider';

/*
	Register components
	Example: _type: ComponentName
*/
const Components = {
  accordionSection: Accordion,
  chat: SMS,
  cardGridSection: SellingPoints,
  pebbleTextAnimation: Banner,
  videoSection: VideoBanner,
  textSection: TextSection,
  textBlockSection: TextBlocks,
  textArticleSection: TextArticle,
  pebbleIconSection: PebbleSection,
  infoBoxSection: ScrollCards,
  pebbleFontPreviewSection: PebbleFontPreview,
  parallaxingImagesSection: ScrollImageGallery,
  slides: ExamplesSlider,
};

export default (block) => {
  if (typeof Components[block._type] !== 'undefined') {
    return React.createElement(Components[block._type], {
      key: block._key,
      id: block._key,
      block,
    });
  }
  return React.createElement(
    () =>
      process.env.NODE_ENV !== 'production' ? (
        <div>Dette komponentet: {block._type} eksisterer ikke enda. Ta kontakt med admin.</div>
      ) : null,
    { key: block._uid }
  );
};
