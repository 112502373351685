// extracted by mini-css-extract-plugin
export const main = "PebbleSection-module--main--2DdCk";
export const backgroundPebble = "PebbleSection-module--backgroundPebble--3Gncz";
export const wrapper = "PebbleSection-module--wrapper--3BXDr";
export const heading = "PebbleSection-module--heading--3HOrd";
export const content = "PebbleSection-module--content--3qhbD";
export const text = "PebbleSection-module--text--39Oap";
export const pebble = "PebbleSection-module--pebble--2BpWW";
export const first = "PebbleSection-module--first--BTgFS";
export const second = "PebbleSection-module--second--3KHX5";
export const third = "PebbleSection-module--third--2TRzC";