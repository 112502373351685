import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import Headroom from 'react-headroom';
import * as styles from './Nav.module.scss';

import Logo from '../../images/logo.svg';
import LogoWhitePurple from '../../images/logo-white-purple-2.svg';

import MenuOpen from '../../icons/menu-open.svg';
import MenuClose from '../../icons/menu-close.svg';

// todo: Maybe make the pattern into a component since it is used multiple places?
import PatternTop from '../../images/Pattern-menu-top.svg';
import PatternBottom from '../../images/Pattern-menu-bottom.svg';

const Nav = ({ theme, bg: useBackground, hasDarkHeader }) => {
  const [toggled, setToggled] = useState();
  const [isPinned, setIsPinned] = useState();
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      sanitySiteSettings {
        menuItems {
          path
          title
        }
      }
    }
  `);

  let ComputedLogo;

  if (toggled || isPinned) {
    ComputedLogo = Logo;
  } else {
    ComputedLogo = hasDarkHeader ? LogoWhitePurple : Logo;
  }

  const toggleMenu = () => {
    const newToggled = !toggled;
    setToggled(newToggled);
  };

  if (typeof document !== 'undefined') {
    if (toggled) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
  }

  const classes = classNames(styles.wrapper, {
    [styles.light]: theme === 'light',
    [styles.toggled]: toggled,
  });

  const onKeyPressed = (e) => {
    if (e.key === 'Escape') {
      if (toggled) {
        setToggled(false);
      }
    }
  };

  return (
    <div className={classes} onKeyDown={onKeyPressed}>
      <Headroom
        disableInlineStyles
        onPin={() => {
          setIsPinned(true);
        }}
        onUnfix={() => {
          setIsPinned(false);
        }}
      >
        <div className={classNames(styles.container, { [styles.bg]: useBackground })}>
          <a href="/" className={styles.logo}>
            <ComputedLogo />
          </a>

          <button
            aria-label={toggled ? 'Lukk meny' : 'Åpne meny'}
            className={styles.menuButton}
            onClick={toggleMenu}
          >
            {toggled ? <MenuClose /> : <MenuOpen />}
            <span>Meny</span>
          </button>
        </div>
      </Headroom>

      <nav
        className={classNames(styles.navigation, {
          [styles.toggled]: toggled,
        })}
      >
        <PatternTop className={styles.patternTop} />
        <PatternBottom className={styles.patternBottom} />
        <div className={styles.navWrapper}>
          {data.sanitySiteSettings.menuItems &&
            data.sanitySiteSettings.menuItems.map((navItem, index) => (
              <li key={`nav-item-${index}`} style={{ animationDelay: `${index * 0.08}s` }}>
                <a href={navItem.path}>{navItem.title}</a>
              </li>
            ))}
        </div>
      </nav>
    </div>
  );
};

export default Nav;
