// extracted by mini-css-extract-plugin
export const outer = "ScrollCards-module--outer--38dGT";
export const main = "ScrollCards-module--main--2ocYK";
export const sections = "ScrollCards-module--sections--mntRK";
export const section = "ScrollCards-module--section--1ZdF5";
export const title = "ScrollCards-module--title--1nwnf";
export const sectionContent = "ScrollCards-module--sectionContent--1c-f1";
export const visible = "ScrollCards-module--visible--2kdxZ";
export const card = "ScrollCards-module--card--_QSlN";
export const cardBg = "ScrollCards-module--cardBg--2pHQx";
export const cardContent = "ScrollCards-module--cardContent--QKKOF";
export const cardIcon = "ScrollCards-module--cardIcon--bH3t-";
export const cardIconImg = "ScrollCards-module--cardIconImg--2oEx0";
export const cardIconPebble = "ScrollCards-module--cardIconPebble--2E14H";