import React from 'react';
import classNames from 'classnames';
import * as styles from './TextSection.module.scss';

import RichText from '../RichText/RichText';

const TextSection = ({ block }) => {
  const classes = classNames(
    styles.wrapper,
    { [styles.white]: block.bgcolor === '#FFFFFF' },
    { [styles.grey]: block.bgcolor === '#f2f2f2' },
    { [styles.purple]: block.bgcolor === '#7907B3' }
  );

  return (
    <section className={classes} style={{ backgroundColor: block.bgcolor }}>
      <div className="site-content-container">
        {block.heading && <h2> {block.heading} </h2>}
        <div className="two-col-text">
          <RichText blocks={block._rawText} />
        </div>
      </div>
    </section>
  );
};

export default TextSection;
