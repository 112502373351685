class Inertia {
  constructor(from, to, acc, dec) {
    this.from = from;
    this.to = to;
    this.acc = acc;
    this.dec = dec;
    this.value = this.from;
    this.vel = 0;
  }

  update = newValue => {
    this.vel = this.vel + (newValue - this.value) * this.acc;
    this.vel = this.vel * this.dec;
    this.value += this.vel;
    this.value = this._clamp(this.value);

    return this.value;
  };

  setValue = value => {
    this.vel = 0;
    this.value = this._clamp(value);
    return this.value;
  };

  _clamp = value => {
    return Math.min(this.to, Math.max(this.from, value));
  };
}

export default Inertia;
