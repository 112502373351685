// Mock window if it doesn't exist

const noop = () => {};

const mockWindow =
  typeof window !== 'undefined'
    ? window
    : {
        addEventListener: noop,
        location: {},
        localStorage: {
          getItem: noop,
          setItem: noop,
          removeItem: noop,
        },
        sessionStorage: {
          getItem: noop,
          setItem: noop,
          removeItem: noop,
        },
      };

export default mockWindow;
