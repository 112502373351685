import { useState, useEffect } from 'react';

export default (ref, options = {}) => {
	if (!ref) {
		throw new Error('First seen hook must be given a ref as first argument');
	}

	const [seen, setSeen] = useState(false);

	useEffect(() => {
		const onIntersection = entries => {
			entries.forEach(entry => {
				const { intersectionRatio, isIntersecting, target } = entry;

				if (
					target === ref.current &&
					((options.threshold && intersectionRatio > options.threshold) ||
					(!options.threshold && isIntersecting))
				) {
					setSeen(true);

					observer.unobserve(ref.current);
				}
			});
		};

		let observer = { unobserve: () => {}, observe: () => {} };

		if (!seen) {
			observer = new IntersectionObserver(onIntersection, options);

			observer.observe(ref.current);
		}

		return () => {
			if (seen) return;

			observer.unobserve(ref.current);
		};
	}, []);

	return seen;
};
