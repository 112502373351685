import React, { useState, useLayoutEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Slider from 'react-animated-slider';

import Heading from '../Heading/Heading';
import IconRight from '../../icons/arrow-chevron-right.svg';
import IconLeft from '../../icons/arrow-chevron-left.svg';

import 'react-animated-slider/build/horizontal.css';
import * as styles from './ExamplesSlider.module.scss';

const ExamplesSlider = ({ block }) => {
  const [sliderHeight, setSliderHeight] = useState(500);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => handleSlideChange({ slideIndex: 0 }), 500);

    return () => {
      window.clearTimeout(timeout);
    };
  }, []);

  const handleSlideChange = ({ slideIndex }) => {
    const slideEls = document.querySelectorAll(`.${styles.wrapper} .slide`);
    const slideEl = slideEls[slideIndex];
    if (!slideEl) {
      return;
    }

    let totalHeight = 40; // margin offset
    const imageEl = slideEl.querySelector(`.${styles.img}`);
    const figcaptionEl = slideEl.querySelector('figcaption');

    if (imageEl) {
      totalHeight += imageEl.clientHeight;
    }
    if (figcaptionEl) {
      totalHeight += figcaptionEl.clientHeight;
    }

    setSliderHeight(totalHeight);
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <Heading title={block.title} className={styles.heading} />
        <div
          className={styles.sliderContainer}
          style={{
            height: `${sliderHeight}px`,
          }}
        >
          <Slider duration={500} onSlideChange={handleSlideChange} touchDisabled>
            {block.slides.map((slide, index) => (
              <figure className={styles.figure} key={index}>
                <a href={slide.image?.asset?.url} target="_blank" rel="noreferrer">
                  <img className={styles.img} src={slide.image?.asset?.url} alt="Slide" />
                </a>
                <figcaption>
                  <p>{slide.text}</p>
                </figcaption>
              </figure>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

ExamplesSlider.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        image: PropTypes.shape,
      })
    ),
  }),
};

export default ExamplesSlider;
