import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import useFirstSeen from '../../hooks/useFirstSeen';
import * as styles from './TextBlocks.module.scss';

import RichText from '../RichText/RichText';

const Block = ({ block }) => {
  const containerRef = useRef();
  const isVisible = useFirstSeen(containerRef, {
    threshold: 0.2,
  });
  const classes = classNames(styles.textBlockWrapper, {
    [styles.fadeIn]: isVisible,
  });

  return (
    <div className={classes} ref={containerRef}>
      <div className={styles.textBlock}>
        <h2>{block.title}</h2>
        <RichText blocks={block._rawText} />
      </div>
    </div>
  );
};

Block.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    // _rawText: PropTypes.string.isRequired,
  }).isRequired,
};

export default Block;
