/*
@TODO: Cleaner sms fadein animation?
*/

import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './Sms.module.scss';

import Message from './Message';

const Sms = ({ block }) => {
  if (!block.messages) return null;

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        {block.messages.map((message, index) => (
          <Message data={message} key={index} />
        ))}
      </div>
    </section>
  );
};

Sms.propTypes = {
  messages: PropTypes.array,
};

Sms.defaultProps = {
  messages: [],
};

export default Sms;
