import { useRef, useLayoutEffect, useState, useEffect } from 'react';
import touches from 'touches';
import debounce from 'just-debounce-it';

import useInView from './useInView';
import Inertia from '../utils/Inertia';
import window from '../utils/window';

export default (ref) => {
  if (!ref) {
    throw new Error('Missing ref');
  }

  const touchHandler = useRef(null);
  const inertiaPointerPosition = useRef({
    x: new Inertia(0, window.innerWidth, 0.4, 0.4),
    y: new Inertia(0, window.innerHeight, 0.4, 0.4),
  });
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });
  const inView = useInView(ref);

  useLayoutEffect(() => {
    if (!ref.current) return;

    let runAnimationFrame = false;
    const realtimePointerPosition = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 3.5,
    };
    inertiaPointerPosition.current.x.setValue(realtimePointerPosition.x);
    inertiaPointerPosition.current.y.setValue(realtimePointerPosition.y);

    const loop = () => {
      inertiaPointerPosition.current.x.update(realtimePointerPosition.x);
      inertiaPointerPosition.current.y.update(realtimePointerPosition.y);

      setPointerPosition({
        x: inertiaPointerPosition.current.x.value,
        y: inertiaPointerPosition.current.y.value,
      });

      if (runAnimationFrame) {
        requestAnimationFrame(loop);
      }
    };

    loop();

    /**
     * Start animation frame if it's not already running, and schedule stop
     */
    const startAnimationFrame = () => {
      if (runAnimationFrame) return;

      runAnimationFrame = true;
      requestAnimationFrame(loop);

      scheduleStopAnimationFrame();
    };

    /**
     * Kill animation frame after a set delay to make sure inertia is slowed down sufficiently
     */
    const scheduleStopAnimationFrame = debounce(() => {
      runAnimationFrame = false;
    }, 2400);

    const handleTouchMove = (_, pos) => {
      const [x, y] = pos;
      realtimePointerPosition.x = x;
      realtimePointerPosition.y = y;

      startAnimationFrame();
    };

    touchHandler.current = touches(ref.current, {
      filtered: true,
      preventSimulated: false,
    }).on('move', handleTouchMove);

    return () => {
      touchHandler.current.disable();
    };
  }, []);

  useEffect(() => {
    if (!touchHandler.current) return;

    if (inView) {
      touchHandler.current.enable();
    } else {
      touchHandler.current.disable();
    }
  }, [inView]);

  return pointerPosition;
};
