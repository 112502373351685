// extracted by mini-css-extract-plugin
export const videoBlock = "VideoBanner-module--videoBlock--22bew";
export const wrapper = "VideoBanner-module--wrapper--3t0tN";
export const container = "VideoBanner-module--container--sMsuJ";
export const text = "VideoBanner-module--text--1xTzV";
export const button = "VideoBanner-module--button--ccaOv";
export const hide = "VideoBanner-module--hide--8hPcn";
export const wide = "VideoBanner-module--wide--3Qh7C";
export const poster = "VideoBanner-module--poster--2ZhxR";
export const mobile = "VideoBanner-module--mobile--1EE8W";
export const desktop = "VideoBanner-module--desktop--3HjIH";