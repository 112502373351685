import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import * as styles from './VideoBanner.module.scss';

import Button from '../Button/Button';
import Video from '../../components/Video/Video';
import { useInView } from 'react-hook-inview';

const VideoBanner = ({ block, isHero }) => {
  const videoRef = useRef();
  const videoMobileRef = useRef();
  const { title, video, poster, loopVideo, mobileVideo, ctaText } = block;
  const [playing, setPlaying] = useState();
  const [currentPlayer, setCurrentPlayer] = useState();

  const classes = classNames([styles.videoBlock], { [styles.wide]: !isHero });
  const textClass = classNames([styles.container], { [styles.hide]: playing });
  const [ref, isVisible] = useInView({
    threshold: 0.5
  });

  function playVideoPlayer(player) {
    player.current.playFullVideo();
    setPlaying(!playing);
    setCurrentPlayer(player);
  }

  if (!isVisible) {
    if (playing && currentPlayer.current) {
      currentPlayer.current.pauseFullVideo();
      setPlaying(!playing);
    }
  }

  if (!video) return null;

  return (
    <section className={classes}>
      <div ref={ref} className={styles.wrapper}>
        {!playing && loopVideo?.asset && (
          <Video
            src={`https://stream.mux.com/${loopVideo.asset.playbackId}.m3u8`}
            poster={`https://image.mux.com/${loopVideo.asset.playbackId}/thumbnail.jpg?time=0&width=16`}
            dataPoster={`https://image.mux.com/${loopVideo.asset.playbackId}/thumbnail.jpg?time=0`}
            className={styles.desktop}
            noLazy={true}
          />
        )}
        {!playing && !loopVideo && poster && (
          <img className={styles.poster} src={poster?.asset?.url} />
        )}
        <div style={!playing ? { display: 'none' } : null}>
          <Video
            ref={videoRef}
            src={`https://stream.mux.com/${video.asset.playbackId}.m3u8`}
            poster={`https://image.mux.com/${video.asset.playbackId}/thumbnail.jpg?time=0&width=16`}
            dataPoster={`https://image.mux.com/${video.asset.playbackId}/thumbnail.jpg?time=0`}
            className={styles.desktop}
            noLazy={true}
          />
        </div>

        <div style={!playing ? { display: 'none' } : null}>
          {mobileVideo && (
            <Video
              ref={videoMobileRef}
              src={`https://stream.mux.com/${mobileVideo.asset.playbackId}.m3u8`}
              poster={`https://image.mux.com/${mobileVideo.asset.playbackId}/thumbnail.jpg?time=0&width=16`}
              dataPoster={`https://image.mux.com/${mobileVideo.asset.playbackId}/thumbnail.jpg?time=0`}
              className={styles.mobile}
              noLazy={true}
            />
          )}
        </div>

        {
          <div className={textClass}>
            <div className={styles.text}>
              {isHero ? <h1>{title}</h1> : <h2>{title}</h2>}
              {ctaText && (
                <Button
                  className={styles.desktop}
                  onClick={() => playVideoPlayer(videoRef)}
                  text={ctaText}
                />
              )}
              {ctaText && mobileVideo && (
                <Button
                  className={styles.mobile}
                  onClick={() => playVideoPlayer(videoMobileRef)}
                  text={ctaText}
                />
              )}
            </div>
          </div>
        }
      </div>
    </section>
  );
};

export default VideoBanner;
