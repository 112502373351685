import React, { useState } from 'react';

import storage from '../../utils/storage';

import * as styles from './App.module.scss';

const IS_BUILDING = typeof window === 'undefined';
const SKIP_AUTH_CHECK = IS_BUILDING || window.location.hostname === 'localhost';
const AUTHED_STORAGE_KEY = 'authed';
const VALID_EMAILS = ['telia.no'];

const App = ({ children }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authed, setAuthed] = useState(
    SKIP_AUTH_CHECK || storage.read(AUTHED_STORAGE_KEY)
  );
  const [error, setError] = useState('');

  // Show login form if not authed
  if (!authed) {
    const handleSubmit = (e) => {
      e.preventDefault();

      if (
        VALID_EMAILS.some((entry) => email.includes(`@${entry}`)) &&
        password === atob('VGVsaWFUb25l')
      ) {
        storage.write(AUTHED_STORAGE_KEY, '1');
        setAuthed(true);
      } else {
        setError('Feil brukernavn eller passord');
      }
    };

    return (
      <div className={styles.form}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <h1>Logg inn</h1>
          {error && <p className={styles.error}>{error}</p>}
          <label>
            <span className={styles.label}>Brukernavn:</span>
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError('');
              }}
              type="email"
              required
            />
          </label>
          <label>
            <span className={styles.label}>Passord:</span>
            <input
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError('');
              }}
              type="password"
              required
            />
          </label>
          <button type="submit" className={styles.button}>
            Logg inn
          </button>
        </form>
      </div>
    );
  }

  return children;
};

export default App;
