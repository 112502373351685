import React, { useRef } from "react";

import classNames from "classnames";
import useFirstSeen from '../../hooks/useFirstSeen';
import * as styles from "./Sms.module.scss";

import IconTip from './icon-bubbletip.svg';

const Message = ({ data }) => {
	const containerRef = useRef();
	const isVisible    = useFirstSeen( containerRef );
	const msgClasses   = classNames(
		styles.bubble,
		{ [ styles.sender ] : !data.isTelia },
		{ [ styles.big ]    : !!data.big  },
		{ [ styles.fadeIn ] : isVisible }
	)

	return (
		<div className={ msgClasses } ref={ containerRef }>
			{ data.message }
			<IconTip className={ styles.bubbletip } />
		</div>
	)
}

export default Message;
