import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './button.module.scss';

const Button = ({ text, children, href, onClick, target, smallText, className }) => {
  const classes = classNames(className, styles.main, styles.primary);

  // todo: not optimal set up of conditional classes
  return (
    <a
      href={href}
      target={target}
      className={`${classes} ${smallText ? styles.smallText : ''}`}
      onClick={onClick}
      role="button"
    >
      <span>{text}</span>
      {children}
    </a>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  smallText: PropTypes.bool,
  className: PropTypes.string,
};

export default Button;
