import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BackgroundPebble from './Pebble/Pebble';
import Pebble from '../../images/pebbles/third.svg';

import * as styles from './PebbleSection.module.scss';

const PebbleSection = ({ block }) => (
  <section className={styles.main}>
    <BackgroundPebble className={styles.backgroundPebble} />

    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h2 className={styles.heading}>{block.pebbleTitle}</h2>
        <p className={styles.text}>{block.pebbleText}</p>
      </div>
    </div>
    <Pebble className={classNames(styles.pebble, styles.first)} />
    <Pebble className={classNames(styles.pebble, styles.second)} />
    <Pebble className={classNames(styles.pebble, styles.third)} />
  </section>
);

PebbleSection.propTypes = {
  block: PropTypes.shape({
    pebbleTitle: PropTypes.string,
    pebbleText: PropTypes.string,
  }),
};

export default PebbleSection;
