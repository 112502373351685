// Helpers
export const generateId = () => {
  return Math.random().toString(36).substr(2, 9);
};

export const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

export const getFractionBetween = (value, min, max) =>
  (value - min) / (max - min);

export const fetchElement = (selectorOrRef) => {
  let $element = selectorOrRef;
  if (typeof $element === 'string') {
    $element = document.querySelector($element);
  } else if ($element && $element.current) {
    $element = $element.current;
  }
  return $element;
};
