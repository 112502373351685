import { useState, useLayoutEffect } from 'react';
import debounce from 'just-debounce-it';
import Inertia from '../../utils/Inertia';
import window from '../../utils/window';
import { generateId, clamp, getFractionBetween, fetchElement } from './helpers';

// State variables
let runAnimationFrame = false;
let scrollTop = 0;
let scrollHeight = 0;
let windowHeight = window.innerHeight;
let scrollY = 0;

let scrollInertiaYFast = new Inertia(0, 1, 0.25, 0.25);
let scrollInertiaYMedium = new Inertia(0, 1, 0.19, 0.19);
let scrollInertiaYSlow = new Inertia(0, 1, 0.17, 0.16);
scrollInertiaYFast.setValue(0);
scrollInertiaYMedium.setValue(0);
scrollInertiaYSlow.setValue(0);

const updateCallbacks = {};
const resizeListeners = {};

/**
 * Update inertia to variables when body height changes
 */
const updateScrollHeight = () => {
  const newScrollHeight = document.body.scrollHeight;
  if (newScrollHeight !== scrollHeight) {
    scrollHeight = newScrollHeight;
    scrollInertiaYFast.to = scrollHeight + windowHeight;
    scrollInertiaYMedium.to = scrollHeight + windowHeight;
    scrollInertiaYSlow.to = scrollHeight + windowHeight;
  }
};

/**
 * Kill animation frame after a set delay to make sure inertia is slowed down sufficiently
 */
const stopAnimationFrame = debounce(() => {
  runAnimationFrame = false;
}, 2400);

/**
 * Update scroll state variables upon scroll event
 */
window.addEventListener('scroll', () => {
  if (!runAnimationFrame) {
    runAnimationFrame = true;
    requestAnimationFrame(loop);
  }

  scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  scrollY = scrollTop + windowHeight;

  updateScrollHeight();
  stopAnimationFrame();
});

/**
 * Update window height, document height and fire resize callbacks
 */
const handleResize = debounce(() => {
  windowHeight = window.innerHeight;
  updateScrollHeight();
  for (const cb of Object.values(resizeListeners)) {
    cb();
  }
}, 300);

window.addEventListener('resize', handleResize);

/**
 * Update inertia values each rAF
 */
const loop = () => {
  scrollInertiaYFast.update(scrollY);
  scrollInertiaYMedium.update(scrollY);
  scrollInertiaYSlow.update(scrollY);

  for (const cb of Object.values(updateCallbacks)) {
    cb(
      scrollInertiaYFast.value,
      scrollInertiaYMedium.value,
      scrollInertiaYSlow.value
    );
  }

  if (runAnimationFrame) {
    requestAnimationFrame(loop);
  }
};

/**
 * useScrollProgress hook
 *
 * @param {Selector string} fromElement  '#section1'
 * @param {Selector string} toElement '#section2'
 * @param {array} breakpoints [{ id: 'section1', element: '#section1' }, ...]
 */
function useScrollProgress({
  fromElement,
  toElement,
  stickyContainer,
  breakpoints,
} = {}) {
  const [y, setY] = useState({
    fast: 0,
    medium: 0,
    slow: 0,
    visibleSection: null,
    sectionProgress: {},
  });

  useLayoutEffect(() => {
    let startPosition = 0;
    let endPosition = 0;
    let visibleSection;
    let sections = [];
    const sectionProgressById = {};

    /**
     * Calculate section offsets and thresholds upon resize
     */
    const onResize = () => {
      const DEFAULT_SECTION_HEIGHT = windowHeight;

      const $from = fetchElement(fromElement);
      const $to = fetchElement(toElement);
      const $stickyContainer = fetchElement(stickyContainer);

      if (!$from || !$to) return;

      const fromRect = $from.getBoundingClientRect();
      const toRect = $to.getBoundingClientRect();
      let stickyOffset = 0;

      // Calculate correct offset for sticky container currently pinned to the top
      if (fromRect.top === 0 && scrollTop > 0 && $stickyContainer) {
        const stickyContainerRect = $stickyContainer.getBoundingClientRect();
        stickyOffset = stickyContainerRect.top;
      }

      startPosition = scrollTop + stickyOffset + fromRect.top;
      endPosition =
        scrollTop + stickyOffset + toRect.top + toRect.height + windowHeight;

      // Calculate breakpoint thresholds
      sections = [];
      if (Array.isArray(breakpoints)) {
        let offset = 0;

        for (let { id, element } of breakpoints) {
          const $breakpoint = fetchElement(element);
          const breakpointRect = $breakpoint.getBoundingClientRect();
          const sectionHeight = breakpointRect.height || DEFAULT_SECTION_HEIGHT;

          let startPosition = scrollTop + stickyOffset + breakpointRect.top;
          let endPosition = startPosition + sectionHeight;

          // Manually add offset to sections if they don't have a height
          if (!breakpointRect.height) {
            startPosition += offset;
            endPosition += offset;
            offset += DEFAULT_SECTION_HEIGHT;
          }

          //   console.log(id, startPosition, endPosition);
          //   const marker = document.createElement('div');
          //   marker.style.position = 'absolute';
          //   marker.style.left = '10px';
          //   marker.style.top = startPosition + sectionHeight / 2 + 'px';
          //   marker.style.width = '100px';
          //   marker.style.height = '5px';
          //   marker.style.background = 'red';
          //   marker.style.zIndex = 1000;
          //   document.body.appendChild(marker);

          sections.push({
            id,
            startPosition,
            endPosition,
            element: $breakpoint,
          });
        }
      }
    };

    /**
     * Callback whenever scroll inertia position is updated.
     * Values are normalized between 0-1, and saved to state.
     *
     * @param {number} fast
     * @param {number} medium
     * @param {number} slow
     */
    const handleInertiaScrollUpdate = (fast, medium, slow) => {
      // Normalize scroll values between 0-1
      const normalizedProgressSlow = getFractionBetween(
        slow,
        startPosition,
        endPosition
      );
      const normalizedProgressMedium = getFractionBetween(
        medium,
        startPosition,
        endPosition
      );
      const normalizedProgressFast = getFractionBetween(
        fast,
        startPosition,
        endPosition
      );

      // Calculate progress for each section
      for (const section of sections) {
        section.progress = getFractionBetween(
          fast,
          section.startPosition,
          section.endPosition
        );
        const SECTION_CENTER_POSITION = 1;
        section.distance = Math.abs(SECTION_CENTER_POSITION - section.progress);
        section.progress = clamp(section.progress, -0.5, 1.5);

        sectionProgressById[section.id] = section.progress;
        // if (section.id === 'section3') {
        //   console.log(section.id, section.progress);
        // }
      }

      // Find the closest section, and store in visibleSection
      const SECTION_VISIBILITY_THRESHOLD = 0.5;
      const sortedSections = sections.sort((a, b) => a.distance - b.distance);
      if (
        sortedSections[0] &&
        sortedSections[0].distance < SECTION_VISIBILITY_THRESHOLD
      ) {
        visibleSection = sortedSections[0].id;
      } else {
        // visibleSection = null;
      }

      // Update state
      setY({
        fast: normalizedProgressFast,
        medium: normalizedProgressMedium,
        slow: normalizedProgressSlow,
        visibleSection,
        sectionProgress: sectionProgressById,
      });
    };

    // Store update callbacks
    const id = generateId();
    updateCallbacks[id] = handleInertiaScrollUpdate;
    resizeListeners[id] = onResize;

    // Initialize resize and start loop
    handleResize();
    if (!runAnimationFrame) {
      runAnimationFrame = true;
      requestAnimationFrame(loop);
    }

    return () => {
      // Clean up on unmount
      delete updateCallbacks[id];
      delete resizeListeners[id];
    };
  }, [fromElement, toElement]);

  return y;
}

/**
 * Subscribe to scroll updates. Returns callback id
 *
 * @param {function} cb
 */
export const subscribe = (cb) => {
  const id = generateId();
  updateCallbacks[id] = cb;
  return id;
};

/**
 * Unsubscribe to scroll updates with callback id
 *
 * @param {string} id
 */
export const unsubscribe = (id) => {
  delete updateCallbacks[id];
};

/**
 * Manually override scroll position
 * @param {number} yPos
 */
export const setScrollPos = (yPos) => {
  scrollY = yPos + windowHeight;
  scrollInertiaYFast.setValue(scrollY);
  scrollInertiaYMedium.setValue(scrollY);
  scrollInertiaYSlow.setValue(scrollY);
  updateScrollHeight();
};

export default useScrollProgress;
