import React from 'react';
import classNames from 'classnames';
import * as styles from './TextArticle.module.scss';

import RichText from '../RichText/RichText';

const TextArticle = ({ block, className }) => {
  const classes = classNames(className, styles.wrapper);

  return (
    <section className={classes}>
      <div className="site-content-container">
        <div className={styles.heading}>
          {block._rawTitle && (
            <h1>
              <RichText blocks={block._rawTitle} />
            </h1>
          )}
          {block.lead && <h4>{block.lead}</h4>}
        </div>
        <div className={styles.article}>
          <div className="two-col-text">
            <RichText blocks={block._rawTextSectionFirst} />
          </div>
          {block._rawSubTitle && (
            <h2>
              <RichText blocks={block._rawSubTitle} />
            </h2>
          )}
          <div className="two-col-text">
            <RichText blocks={block._rawTextSectionSecond} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextArticle;
