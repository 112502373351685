import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

const serializers = {
  types: {}
};

const RichText = ({ blocks }) => {
  if (!Array.isArray(blocks)) return null;

  // blocks.map((i) => {
  //   i.markDefs = [];
  //   return i;
  // });

  return <BlockContent blocks={blocks} serializers={serializers} className="rich-text-wrapper" />;
};

export default RichText;
