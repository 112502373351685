import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import useFirstSeen from '../../hooks/useFirstSeen';
import useWindowSize from '../../hooks/useWindowSize';

import RichText from '../RichText/RichText';

import * as styles from './PebbleFontPreview.module.scss';

function maxLength(event) {
  const len = event.target.innerText.length;

  if (len > 20) {
    if (/^.$/u.test(event.key)) {
      event.preventDefault();
    }
  }
}

function placeCaretAtEnd(el) {
  el.focus();
  if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange !== 'undefined') {
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
}

const PebbleFontPreview = ({ block }) => {
  const windowSize = useWindowSize();
  const editableTextRef = useRef();
  const isVisible = useFirstSeen(editableTextRef);
  const [editableText, setEditableText] = useState('Pebble');
  const [editableTextStyle, setEditableTextStyle] = useState({
    fontSize: '220px',
  });

  const mobileBreakpoint = 730;

  const { _rawHead: head, _rawFooter: footer } = block;

  const handleInput = (event) => {
    setEditableText(event.target.value);
  };

  /* Make text focused when in view. Make sure caret is at the end of text */

  useEffect(() => {
    const isMobile = windowSize.width <= mobileBreakpoint;
    const defaultFontSize = isMobile ? 80 : 220;
    const defaultNumberOfChars = 6;

    const formattedEditableText = editableText
      .replace(/ /g, '')
      .replace(/&nbsp;/g, '')
      .replace(/<br>/g, '');
    const numberOfChars = formattedEditableText.length;

    if (numberOfChars > defaultNumberOfChars) {
      const newFontSize = (defaultNumberOfChars / numberOfChars) * defaultFontSize;
      setEditableTextStyle({
        fontSize: `${newFontSize}px`,
      });
    } else {
      setEditableTextStyle({
        fontSize: `${defaultFontSize}px`,
      });
    }

    if (isVisible) {
      placeCaretAtEnd(editableTextRef.current);
    }
  }, [isVisible, editableText, windowSize.width]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <RichText blocks={head} />
      </div>
      <ContentEditable
        className={styles.editableText}
        style={editableTextStyle}
        innerRef={editableTextRef}
        html={editableText}
        disabled={false}
        onChange={handleInput}
        onKeyDown={maxLength}
        spellCheck="false"
      />
      <div className={styles.footer}>
        <RichText blocks={footer} />
      </div>
    </div>
  );
};

PebbleFontPreview.propTypes = {
  block: PropTypes.shape({
    _rawHead: PropTypes.array.isRequired,
    _rawFooter: PropTypes.array.isRequired,
  }),
};

export default PebbleFontPreview;
