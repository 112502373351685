import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './Banner.module.scss';

import window from '../../utils/window';
import usePointerPosition from '../../hooks/usePointerPosition';
import useWindowSize from '../../hooks/useWindowSize';

const Banner = ({ block }) => {
  const { first, second, third } = block;
  const container = useRef();
  const initialY = useRef(0);
  const maskOffset = useRef({
    x: (window.innerWidth * 0.19) / 2,
    y: (window.innerWidth * 0.19) / 2,
  });
  const pointerPosition = usePointerPosition(container);
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const isMobile = windowWidth < 960;

  useEffect(() => {
    const isMobileLocal = windowWidth < 960;
    maskOffset.current.x = (windowWidth * (isMobileLocal ? 0.25 : 0.19)) / 2;
    maskOffset.current.y = (windowHeight * (isMobileLocal ? 0.25 : 0.19)) / 2;
  }, [windowWidth, windowHeight]);

  if (isMobile && !initialY.current) {
    initialY.current = pointerPosition.y;
  }

  const maskPosition = `${pointerPosition.x - maskOffset.current.x}px ${
    (isMobile ? initialY.current : pointerPosition.y) - maskOffset.current.y
  }px`;

  const bannerText = (
    <h2>
      <span>{first}</span>
      <span>{second}</span>
      <span>{third}</span>
    </h2>
  );

  return (
    <section className={styles.wrapper} ref={container}>
      <div className={classNames(styles.container, styles.content)}>{bannerText}</div>
      {!isMobile && (
        <div
          aria-hidden
          className={classNames(styles.container, styles.mask)}
          style={{
            WebkitMaskPosition: maskPosition,
            maskPosition,
          }}
        >
          {bannerText}
        </div>
      )}
    </section>
  );
};

Banner.propTypes = {
  block: PropTypes.shape({
    first: PropTypes.string.isRequired,
    second: PropTypes.string.isRequired,
    third: PropTypes.string.isRequired,
  }),
};

export default Banner;
