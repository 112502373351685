// extracted by mini-css-extract-plugin
export const wrapper = "Nav-module--wrapper--wTO7B";
export const toggled = "Nav-module--toggled--1aQ7D";
export const light = "Nav-module--light--1ODmb";
export const logo = "Nav-module--logo--2sysj";
export const menuButton = "Nav-module--menuButton--2FrRN";
export const container = "Nav-module--container--2iW43";
export const bg = "Nav-module--bg--2U0Q8";
export const navigation = "Nav-module--navigation--1sGOj";
export const patternBottom = "Nav-module--patternBottom--20rXA";
export const patternTop = "Nav-module--patternTop--2wMbM";
export const navWrapper = "Nav-module--navWrapper--2jcv9";