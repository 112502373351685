import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './Accordion.module.scss';

import Heading from '../Heading/Heading';
import Icon from '../../icons/arrow-down.svg';
import textWithWhiteSpace from '../../utils/textWithWhiteSpace';

const Accordion = ({ block }) => (
  <section className={styles.wrapper}>
    <div className={styles.container}>
      <Heading
        className={styles.accordionHeading}
        title={block.title}
        text={block.lead}
        alignment="left"
      />
      <div className={styles.accordions}>
        {block.accordion.map((point, i) => (
          <details className={styles.accordion} key={i}>
            <summary>
              {point.title} <Icon />{' '}
            </summary>
            <p>{textWithWhiteSpace(point.text)}</p>
          </details>
        ))}
      </div>
    </div>
  </section>
);

Accordion.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    lead: PropTypes.string.isRequired,
    accordion: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Accordion;
