import React from 'react';

import Pebble from './Pebble/Pebble';
import useScrollProgress from '../../hooks/useScrollProgress';

import * as styles from './StaticBackgroundPebbles.module.scss';

import firstPebbleUrl from '../../images/pebbles/first.png';
import secondPebbleUrl from '../../images/pebbles/second.png';
import ThirdPebble from '../../images/pebbles/third.svg';

const getPebbleStyle = (progress, index) => {
  const scale = 1.1 - progress * 0.3;
  const direction = index % 2 === 0 ? 1 : -1;
  const rotation = index * 40 * direction + progress * direction * 60 + 55;

  return {
    transform: `scale(${scale}) rotate(${rotation}deg)`,
  };
};

const BackgroundPebbles = () => {
  const scrollProgress = useScrollProgress({
    fromElement: 'body',
    toElement: 'body',
  });

   return (
     <div className={styles.main}>
       <img src={firstPebbleUrl} className={styles.pebble} style={getPebbleStyle(scrollProgress.fast, 1)} alt="First pebble" />
       <img src={secondPebbleUrl} className={styles.pebble} style={getPebbleStyle(scrollProgress.medium, 1)} alt="Second pebble" />
       {/* <SecondPebble className={styles.pebble} /> */}
       <ThirdPebble className={styles.pebble} style={getPebbleStyle(scrollProgress.medium, 1)} />

       {/* <img className={styles.pebble} style={getPebbleStyle(scrollProgress.medium, 3)} src={firstPebble} alt="First pebble" /> */}

       {/* <Pebble
        className={styles.pebble}
        style={getPebbleStyle(scrollProgress.medium, 3)}
      />
      <Pebble
        className={styles.pebble}
        style={getPebbleStyle(scrollProgress.slow, 2)}
      />
      <Pebble
        className={styles.pebble}
        style={getPebbleStyle(scrollProgress.fast, 4)}
      />
      <Pebble
        className={styles.pebble}
        style={getPebbleStyle(scrollProgress.medium, 4)}
      /> */}
     </div>
  );
};
export default BackgroundPebbles;
