import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useScrollProgress from '../../hooks/useScrollProgress';
  import useWindowSize from '../../hooks/useWindowSize';

import * as styles from './ScrollImageGallery.module.scss';
import TextArticle from '../TextArticle/TextArticle';
import RichText from '../RichText/RichText';

const SPEED_TO_DY = {
  fast: 1400,
  medium: 800,
  slow: 400,
};
const INDEX_TO_SPEED = ['medium', 'slow', 'fast', 'slow', 'fast', 'medium', 'fast'];
const WINDOW_SIZE_MOBILE = 750;

const ScrollImageGallery = ({ block }) => {
  const scrollProgress = useScrollProgress({
    fromElement: '#scrollImageGallery',
    toElement: '#scrollImageGallery',
  });

  const windowSize = useWindowSize();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (windowSize.width < WINDOW_SIZE_MOBILE) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
     if (windowSize.width < WINDOW_SIZE_MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize.height, windowSize.width]);

  return (
    <>
      <section className={styles.main} id="scrollImageGallery">
        <div className={styles.stickyTitle}>
          <div className={styles.stickyTitleContent}>
            {block._rawTitle && <RichText blocks={block._rawTitle.text} />}
          </div>
        </div>
        <div className={styles.images}>
          {block.images.map((entry, i) => {
            const url = entry.asset?.url;
            const speed = INDEX_TO_SPEED[i % INDEX_TO_SPEED.length];
            const translateY = scrollProgress[speed] * -SPEED_TO_DY[speed];

            return (
              <figure
                key={i}
                style={isMobile ? null : {
                  transform: `translate3d(0,${translateY}px,0)`,
                }}
              >
                <img src={url} />
              </figure>
            );
          })}
        </div>
      </section>
      <div className={styles.bottom}>
        <div className={styles.bottomContent}>
          <TextArticle block={block.textContent} className={styles.article} />
        </div>
      </div>
    </>
  );
};

ScrollImageGallery.propTypes = {
  heading: PropTypes.string,
  lead: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
};

ScrollImageGallery.defaultProps = {
  images: [],
};

export default ScrollImageGallery;
