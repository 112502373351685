import React from 'react';

export default function textWithWhiteSpace(s = '') {
  if (!s) {
    return '';
  }

  return s
    .split('\n')
    .reduce((total, line, index) => [total, <br key={index} />, line]);
}
