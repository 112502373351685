import { useState, useEffect } from 'react';

export default (ref, options = {}) => {
  if (!ref) {
    throw new Error('InView hook must be given a ref as first argument');
  }

  const [inView, set] = useState(null);

  useEffect(() => {
    const ioTarget = ref.current;

    if (!ioTarget) return;

    const onIntersection = (entries) => {
      entries.forEach(({ intersectionRatio, isIntersecting, target }) => {
        if (target === ioTarget) {
          set(
            (options.threshold && intersectionRatio >= options.threshold) ||
              (!options.threshold && isIntersecting)
          );
        }
      });
    };

    const io = new IntersectionObserver(onIntersection, options);

    io.observe(ioTarget);

    return () => {
      io.unobserve(ioTarget);
      io.disconnect();
    };
  }, [ref, options]);

  return inView;
};
