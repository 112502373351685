import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './TextBlocks.module.scss';

import StaticBackgroundPebbles from '../StaticBackgroundPebbles/StaticBackgroundPebbles';

import Block from './Block';

const TextBlocks = ({ block }) => {
  let BackgroundComponent = null;

  switch (block.backgroundType) {
    case 'pebbles-1':
      BackgroundComponent = StaticBackgroundPebbles;
      break;

    default:
      BackgroundComponent = () => <h1>DEFAULT CASE</h1>;
  }

  return (
    <section className={styles.wrapper} style={{ backgroundColor: block.bgcolor }}>
      <BackgroundComponent />
      <div className="site-content-container">
        <div>
          {block.textBlocks.map((textBlock, index) => (
            <Block block={textBlock} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

TextBlocks.propTypes = {
  block: PropTypes.shape({
    backgroundType: PropTypes.string,
    textBlocks: PropTypes.arrayOf(PropTypes.shape),
  }).isRequired,
};

export default TextBlocks;
