import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './SellingPoints.module.scss';

import Heading from '../Heading/Heading';
import RichText from '../RichText/RichText';

const SellingPoints = ({ block }) => {
  return (
    <section className={styles.wrapper}>
      <div className="site-content-container">
        <Heading title={block.title} text={block.lead} theme="light" alignment="left" />
        <div className={styles.boxes}>
          {Array.isArray(block._rawCardGrid) &&
            block._rawCardGrid.map((point, i) => (
              <div className={styles.box} key={i}>
                <h2>{point.title}</h2>
                <RichText blocks={point.text} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

SellingPoints.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    lead: PropTypes.string.isRequired,
    _rawCardGrid: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default SellingPoints;
